import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`This module is in beta. Send us an email in case of any integration issue.`}</em></p>
    <p>{`Analytics, licensing and bug reports for Swift MacOS applications.`}</p>
    <p>{`We made it as simple as possible to report the data you need to analyze your app and improve it.`}</p>
    <p>{`To start using this package, sign up and get an app tracking ID on `}<a parentName="p" {...{
        "href": "https://nucleus.sh"
      }}>{`Nucleus.sh`}</a>{`. `}</p>
    <p><em parentName="p">{`Make sure to check enable both "Incoming and outgoing connections" for your app in XCode under "Signing & Capabilities"`}</em>{` `}</p>
    <p>{`If you have a server-side Swift application, or maybe a cross-platform (for example Linux & macOS) app/library, and you would like to log, we think targeting this logging API package is a great idea. Below you'll find all you need to know to get started.`}</p>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <p>{`Sign up and get a tracking ID for your app `}<a parentName="p" {...{
        "href": "https://nucleus.sh"
      }}>{`here`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`.package(url: "https://github.com/nucleus-sh/NucleusSwift.git", from: "1.0.0"),
`}</code></pre>
    <p>{`and to your application/library target, add "NucleusSwift" to your dependencies, e.g. like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`.target(name: "ExampleApp", dependencies: ["NucleusSwift"]),
`}</code></pre>
    <h3 {...{
      "id": ""
    }}></h3>
    <p>{`In AppDelegate, import the library`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`import NucleusSwift
Nucleus.shared.setup("my-app-id")

...
func applicationDidFinishLaunching(_ aNotification: Notification) {
    ...
    Nucleus.shared.appStarted()
}
`}</code></pre>
    <p>{`Then you can use the module from anywhere in your app:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`Nucleus.shared.track("BTN-CLICKED")

Nucleus.shared.setUserId("richard_hendrix")
`}</code></pre>
    <h4 {...{
      "id": "debugging"
    }}>{`Debugging`}</h4>
    <p>{`If you are having troubles with the module it can be useful to enable logging:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`Nucleus.shared.debug = true
`}</code></pre>
    <h3 {...{
      "id": "identify-your-users"
    }}>{`Identify your users`}</h3>
    <p>{`You can track specific users actions on the 'User Explorer' section of your dashboard.`}</p>
    <p>{`For that, you can supply an `}<inlineCode parentName="p">{`userId`}</inlineCode>{` when initing the Nucleus module. `}</p>
    <p>{`It can be your own generated ID, an email, username... etc.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`Nucleus.shared.setUserId("someUniqueUserId")
`}</code></pre>
    <h3 {...{
      "id": "add-properties"
    }}>{`Add properties`}</h3>
    <p>{`You can report custom data along with the automatic data.`}</p>
    <p>{`Those will be visible in your user dashboard if you previously set an user ID.`}</p>
    <p>{`The module will remember past properties so you can use `}<inlineCode parentName="p">{`Nucleus.shared.setProps`}</inlineCode>{` multiple times without overwriting past props.`}</p>
    <p>{`Properties can either `}<strong parentName="p">{`numbers`}</strong>{`, `}<strong parentName="p">{`strings`}</strong>{` or `}<strong parentName="p">{`booleans`}</strong>{`.
Nested properties or arrays aren't supported at the moment.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`Nucleus.shared.setProps([
    "age": 34,
    "name": "Richard Hendricks",
    "jobType": "CEO"
])
`}</code></pre>
    <p>{`Enable overwrite: set the second parameter as true to overwrite past properties. `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`Nucleus.shared.setProps([
    "age": 23
], overwrite: true)
`}</code></pre>
    <h3 {...{
      "id": "events"
    }}>{`Events`}</h3>
    <p>{`After initializing Nucleus, you can report your own custom events.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`Nucleus.shared.track("PLAYED_TRACK")
`}</code></pre>
    <p>{`They are a couple event names that are reserved by Nucleus: `}<inlineCode parentName="p">{`init`}</inlineCode>{`, `}<inlineCode parentName="p">{`error:`}</inlineCode>{` and `}<inlineCode parentName="p">{`nucleus:`}</inlineCode>{`. Don't report events containing these strings.`}</p>
    <h4 {...{
      "id": "attach-more-data"
    }}>{`Attach more data`}</h4>
    <p>{`You can also add extra information to tracked events. Properties can either `}<strong parentName="p">{`numbers`}</strong>{`, `}<strong parentName="p">{`strings`}</strong>{` or `}<strong parentName="p">{`booleans`}</strong>{`.
Nested properties or arrays aren't supported at the moment.`}</p>
    <p>{`Example`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`Nucleus.track("PLAYED_TRACK", [
    "trackName": "My Awesome Song",
    "duration": 120
])
`}</code></pre>
    <h3 {...{
      "id": "errors"
    }}>{`Errors`}</h3>
    <p>{`You can catch and report errors. That can be useful to debug what went wrong once your app is deployed in production.`}</p>
    <p>{`You need to supply an ID and a message to your errors. The package will extract a stracktrace that you will be able to see in your dashboard.`}</p>
    <p>{`Example`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`Nucleus.shared.trackError(id: "API-ERROR", message: "Missing required parameter")
`}</code></pre>
    <h3 {...{
      "id": "toggle-tracking"
    }}>{`Toggle tracking`}</h3>
    <p>{`This will completely disable any communication with Nucleus' servers.`}</p>
    <p>{`To opt-out your users from tracking, use the following methods:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`Nucleus.shared.disableTracking()
`}</code></pre>
    <p>{`and to opt back in:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`Nucleus.shared.enableTracking()
`}</code></pre>
    <p>{`This change won't persist after restarts so you have to handle the saving of the settings.`}</p>
    <hr></hr>
    <p>{`Contact `}<strong parentName="p"><a parentName="strong" {...{
          "href": "mailto:hello@nucleus.sh"
        }}>{`hello@nucleus.sh`}</a></strong>{` for any inquiry`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      